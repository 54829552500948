import React from "react"
import { Helmet } from "react-helmet"

function SEO({ desc, title, keywords, ogTitle, ogDesc, ogImage }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "fr",
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: desc,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
      ]}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default SEO
